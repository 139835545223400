const PENDING = 'PENDING'
const PAID = 'PAID'
const FAILED = 'FAILED'
const EXPIRED = 'EXPIRED'

export default {
  PENDING,
  PAID,
  FAILED,
  EXPIRED,
}
