const CASH = 'CASH'
const ONLINE = 'ONLINE'
const CRYPTO = 'CRYPTO'
const CREDIT_CARD = 'CREDIT_CARD'

export default {
  CASH,
  ONLINE,
  CRYPTO,
  CREDIT_CARD
}
